export const breadcrumbs = {
  dashboard: { crumbs: [{ text: 'breadcrumbs.dashboard', href: '/' }] },
  'all-extensions': {
    crumbs: [{ text: 'breadcrumbs.extensions-list', href: '/extensions' }]
  },
  'monitor-extensions': {
    crumbs: [
      { text: 'breadcrumbs.monitor-extensions', href: '/extensions/monitor' }
    ]
  },
  'extension-create': {
    crumbs: [
      { text: 'breadcrumbs.extensions-list', href: '/extensions' },
      { text: 'breadcrumbs.extension-create', href: '/extensions/create' }
    ]
  },
  'extension-update': {
    crumbs: [
      { text: 'breadcrumbs.extensions-list', href: '/extensions' },
      { text: 'breadcrumbs.extension-update', href: '/extensions/update/:id' }
    ]
  },
  'call-reporting': {
    crumbs: [{ text: 'breadcrumbs.call-reporting', href: '/call-reporting' }]
  },
  'billing-rates': {
    crumbs: [{ text: 'breadcrumbs.billing-rates', href: '/rates' }]
  },
  'billing-refill': {
    crumbs: [{ text: 'breadcrumbs.billing-refill', href: '/refill' }]
  },
  'billing-payments': {
    crumbs: [{ text: 'breadcrumbs.billing-payments', href: '/payments' }]
  },
  'billing-charges': {
    crumbs: [{ text: 'breadcrumbs.billing-charges', href: '/charges' }]
  },
  'billing-invoices': {
    crumbs: [{ text: 'breadcrumbs.billing-invoices', href: '/invoices' }]
  },
  usage: {
    crumbs: [{ text: 'breadcrumbs.usage', href: '/usage', disabled: true }]
  },
  'usage-summary': {
    crumbs: [
      { text: 'breadcrumbs.usage', href: '/usage', disabled: true },
      { text: 'breadcrumbs.summary', href: '/usage/summary' }
    ]
  },
  'usage-detailed': {
    crumbs: [
      { text: 'breadcrumbs.usage', href: '/usage', disabled: true },
      { text: 'breadcrumbs.detailed', href: '/usage/detailed' }
    ]
  },
  'order-confirmation': {
    crumbs: [
      {
        text: 'breadcrumbs.order-confirmation',
        href: '/order-confirmation',
        disabled: true
      }
    ]
  },
  'order-confirmation-flows-list': {
    crumbs: [
      {
        text: 'breadcrumbs.order-confirmation-flows',
        href: '/order-confirmation/flows'
      }
    ]
  },
  'order-confirmation-create-flow': {
    crumbs: [
      { text: 'breadcrumbs.flows-list', href: '/order-confirmation/flows' },
      {
        text: 'breadcrumbs.create-flow',
        href: '/order-confirmation/flows/create'
      }
    ]
  },
  'order-confirmation-edit-flow': {
    crumbs: [
      { text: 'breadcrumbs.flows-list', href: '/order-confirmation/flows' },
      {
        text: 'breadcrumbs.edit-flow',
        href: '/order-confirmation/flows/edit/:flowId'
      }
    ]
  },
  'order-confirmation-calls': {
    crumbs: [
      {
        text: 'breadcrumbs.order-confirmation-calls',
        href: '/order-confirmation/calls'
      }
    ]
  },
  'order-confirmation-excel-calls-list': {
    crumbs: [
      { text: 'breadcrumbs.calls', href: '/order-confirmation/calls' },
      {
        text: 'breadcrumbs.excel',
        href: '/order-confirmation/calls/excel/list'
      }
    ]
  },
  'order-confirmation-excel-call-file': {
    crumbs: [
      { text: 'breadcrumbs.calls', href: '/order-confirmation/calls' },
      {
        text: 'breadcrumbs.excel',
        href: '/order-confirmation/calls/excel/list'
      },
      { text: 'breadcrumbs.corrupted', href: '/order-confirmation/excel/:id' }
    ]
  },
  'order-confirmation-calls-list': {
    crumbs: [
      { text: 'breadcrumbs.calls', href: '/order-confirmation/calls' },
      { text: 'breadcrumbs.single', href: '/order-confirmation/calls/list' }
    ]
  },
  'call-campaign': {
    crumbs: [{ text: 'breadcrumbs.call-campaign', href: '/call-campaign' }]
  },
  'call-campaign-flows-list': {
    crumbs: [
      { text: 'breadcrumbs.call-campaign-flows', href: '/call-campaign/flows' }
    ]
  },
  'call-campaign-create-flow': {
    crumbs: [
      { text: 'breadcrumbs.call-campaign-flows', href: '/call-campaign/flows' },
      { text: 'breadcrumbs.create', href: '/call-campaign/flows/create' }
    ]
  },
  'call-campaign-edit-flow': {
    crumbs: [
      { text: 'breadcrumbs.call-campaign-flows', href: '/call-campaign/flows' },
      { text: 'breadcrumbs.edit', href: '/call-campaign/flows/edit/:flowId' }
    ]
  },
  'call-campaign-calls': {
    crumbs: [
      { text: 'breadcrumbs.call-campaign-calls', href: '/call-campaign/calls' }
    ]
  },
  'call-campaign-excel-calls-list': {
    crumbs: [
      { text: 'breadcrumbs.call-campaign-calls', href: '/call-campaign/calls' },
      { text: 'breadcrumbs.excel', href: '/call-campaign/calls/excel/list' }
    ]
  },
  'call-campaign-excel-call-file': {
    crumbs: [
      { text: 'breadcrumbs.call-campaign-calls', href: '/call-campaign/calls' },
      { text: 'breadcrumbs.excel', href: '/call-campaign/calls/excel/list' },
      { text: 'breadcrumbs.corrupted', href: '/call-campaign/excel/:id' }
    ]
  },
  'call-campaign-calls-list': {
    crumbs: [
      { text: 'breadcrumbs.call-campaign-calls', href: '/call-campaign/calls' },
      { text: 'breadcrumbs.single', href: '/call-campaign/calls/:type/list' }
    ]
  },
  'all-agents': {
    crumbs: [{ text: 'breadcrumbs.all-agents', href: '/agents' }]
  },
  'agent-create': {
    crumbs: [
      { text: 'breadcrumbs.all-agents', href: '/agents' },
      { text: 'breadcrumbs.create-agent', href: '/agents/create' }
    ]
  },
  'agent-update': {
    crumbs: [
      { text: 'breadcrumbs.all-agents', href: '/agents' },
      { text: 'breadcrumbs.update-agent', href: '/agents/:id/update' }
    ]
  },
  'inno-support': {
    crumbs: [{ text: 'breadcrumbs.inno-support', href: '/inno-support' }]
  },
  settings: { crumbs: [{ text: 'breadcrumbs.settings', href: '/settings' }] },
  'change-password': {
    crumbs: [
      { text: 'breadcrumbs.settings', href: '/settings' },
      {
        text: 'breadcrumbs.account-settings',
        href: '/settings/account-settings'
      },
      {
        text: 'breadcrumbs.change-password',
        href: '/settings/account-settings/change-password'
      }
    ]
  },
  'update-organization-info': {
    crumbs: [
      { text: 'breadcrumbs.settings', href: '/settings' },
      {
        text: 'breadcrumbs.account-settings',
        href: '/settings/account-settings'
      },
      {
        text: 'breadcrumbs.update-organization-info',
        href: '/settings/account-settings/update-organization-info'
      }
    ]
  },
  'after-call': {
    crumbs: [
      { text: 'breadcrumbs.settings', href: '/settings' },
      { text: 'breadcrumbs.call-settings', href: '/settings/call-settings' },
      {
        text: 'breadcrumbs.after-call',
        href: '/settings/call-settings/after-call'
      }
    ]
  },
  'default-survey': {
    crumbs: [
      { text: 'breadcrumbs.settings', href: '/settings' },
      { text: 'breadcrumbs.call-settings', href: '/settings/call-settings' },
      {
        text: 'breadcrumbs.default-survey',
        href: '/settings/call-settings/default-survey'
      }
    ]
  },
  tags: { crumbs: [{ text: 'breadcrumbs.all-tags', href: '/tags' }] },
  'webrtc-credentials': {
    crumbs: [
      {
        text: 'breadcrumbs.webrtc-credentials',
        href: '/developers/webrtc-credentials'
      }
    ]
  },
  'create-webrtc-credentials': {
    crumbs: [
      {
        text: 'breadcrumbs.webrtc-credentials',
        href: '/developers/webrtc-credentials'
      },
      {
        text: 'breadcrumbs.create',
        href: '/developers/webrtc-credentials/create'
      }
    ]
  },
  'zendesk-credentials': {
    crumbs: [
      {
        text: 'breadcrumbs.zendesk-credentials',
        href: '/developers/zendesk-credentials'
      }
    ]
  },
  'create-zendesk-credentials': {
    crumbs: [
      {
        text: 'breadcrumbs.zendesk-credentials',
        href: '/developers/zendesk-credentials'
      },
      {
        text: 'breadcrumbs.create',
        href: '/developers/zendesk-credentials/create'
      }
    ]
  },
  'api-credentials': {
    crumbs: [
      {
        text: 'breadcrumbs.api-credentials',
        href: '/developers/api-credentials'
      }
    ]
  },
  'create-api-credentials': {
    crumbs: [
      {
        text: 'breadcrumbs.api-credentials',
        href: '/developers/api-credentials'
      },
      { text: 'breadcrumbs.create', href: '/developers/api-credentials/create' }
    ]
  },
  'web-call-credentials': {
    crumbs: [
      {
        text: 'breadcrumbs.web-call-credentials',
        href: '/developers/web-call-credentials'
      }
    ]
  },
  'edit-web-call-credentials': {
    crumbs: [
      {
        text: 'breadcrumbs.web-call-credentials',
        href: '/developers/web-call-credentials'
      },
      {
        text: 'breadcrumbs.edit',
        href: '/developers/web-call-credentials/edit/:id'
      }
    ]
  },
  dids: { crumbs: [{ text: 'breadcrumbs.numbers', href: '/dids' }] },
  'auto-dialer': {
    crumbs: [{ text: 'breadcrumbs.auto-dialer', href: '/auto-dialer' }]
  },
  'auto-dialer-create': {
    crumbs: [
      { text: 'breadcrumbs.auto-dialer', href: '/auto-dialer' },
      { text: 'breadcrumbs.create', href: '/auto-dialer/create' }
    ]
  },
  'auto-dialer-attach-customers': {
    crumbs: [
      { text: 'breadcrumbs.auto-dialer', href: '/auto-dialer' },
      {
        text: 'breadcrumbs.attach-customers',
        href: '/auto-dialer/:id/attach-customers'
      }
    ]
  },
  'auto-dialer-update': {
    crumbs: [
      { text: 'breadcrumbs.auto-dialer', href: '/auto-dialer' },
      { text: 'breadcrumbs.update', href: '/auto-dialer/:id/update' }
    ]
  },
  'auto-dialer-corrupted-rows': {
    crumbs: [
      { text: 'breadcrumbs.auto-dialer', href: '/auto-dialer' },
      {
        text: 'breadcrumbs.corrupted-rows',
        href: '/auto-dialer/:id/corrupted-rows'
      }
    ]
  },
  'auto-dialer-campaign-details': {
    crumbs: [
      { text: 'breadcrumbs.auto-dialer', href: '/auto-dialer' },
      {
        text: 'breadcrumbs.campaign-details',
        href: '/auto-dialer/:id/campaign-details'
      }
    ]
  },
  'auto-dialer-campaign-cdrs': {
    crumbs: [
      { text: 'breadcrumbs.auto-dialer', href: '/auto-dialer' },
      { text: 'breadcrumbs.campaign-cdrs', href: '/auto-dialer/:id/cdrs' }
    ]
  },
  'auto-dialer-campaign-uncompleted': {
    crumbs: [
      { text: 'breadcrumbs.auto-dialer', href: '/auto-dialer' },
      {
        text: 'breadcrumbs.uncompleted-requests',
        href: '/auto-dialer/:id/uncompleted-requests'
      }
    ]
  },
  'auto-dialer-update-main-info': {
    crumbs: [
      { text: 'breadcrumbs.auto-dialer', href: '/auto-dialer' },
      {
        text: 'breadcrumbs.update-main-info',
        href: '/auto-dialer/:id/update-main-info'
      }
    ]
  },
  'call-survey': {
    crumbs: [{ text: 'breadcrumbs.call-survey', href: '/call-survey' }]
  },
  'create-call-survey': {
    crumbs: [
      { text: 'breadcrumbs.call-survey', href: '/call-survey' },
      { text: 'breadcrumbs.create', href: '/call-survey/create' }
    ]
  },
  'survey-details': {
    crumbs: [
      { text: 'breadcrumbs.call-survey', href: '/call-survey' },
      {
        text: 'breadcrumbs.survey-details',
        href: '/call-survey/:id/survey-details'
      }
    ]
  },
  'attach-customers': {
    crumbs: [
      { text: 'breadcrumbs.call-survey', href: '/call-survey' },
      {
        text: 'breadcrumbs.attach-customers',
        href: '/call-survey/:id/attach-customers'
      }
    ]
  },
  'corrupted-rows': {
    crumbs: [
      { text: 'breadcrumbs.call-survey', href: '/call-survey' },
      {
        text: 'breadcrumbs.corrupted-rows',
        href: '/call-survey/:id/corrupted-rows'
      }
    ]
  },
  'survey-cdrs': {
    crumbs: [
      { text: 'breadcrumbs.call-survey', href: '/call-survey' },
      { text: 'breadcrumbs.survey-cdrs', href: '/call-survey/:id/survey-cdrs' }
    ]
  },
  'survey-uncompleted': {
    crumbs: [
      { text: 'breadcrumbs.call-survey', href: '/call-survey' },
      {
        text: 'breadcrumbs.uncompleted-requests',
        href: '/call-survey/:id/uncompleted-requests'
      }
    ]
  },
  'survey-calls-list': {
    crumbs: [{ text: 'breadcrumbs.post-call-survey', href: '/survey-calls' }]
  },
  'post-call-surveys-list': {
    crumbs: [
      { text: 'breadcrumbs.post-call-survey', href: '/post-call-surveys' }
    ]
  },
  'create-post-call-survey': {
    crumbs: [
      { text: 'breadcrumbs.post-call-survey', href: '/post-call-surveys' },
      { text: 'breadcrumbs.create', href: '/post-call-surveys/create' }
    ]
  },
  'post-call-survey-details': {
    crumbs: [
      { text: 'breadcrumbs.post-call-survey', href: '/post-call-surveys' },
      {
        text: 'breadcrumbs.survey-details',
        href: '/post-call-surveys/:id/survey-details'
      }
    ]
  },
  'post-call-survey-cdrs': {
    crumbs: [
      { text: 'breadcrumbs.post-call-survey', href: '/post-call-surveys' },
      {
        text: 'breadcrumbs.survey-cdrs',
        href: '/post-call-surveys/:id/survey-cdrs'
      }
    ]
  },
  'call-bridge-flows': {
    crumbs: [
      { text: 'breadcrumbs.call-bridge', href: '/', disabled: true },
      { text: 'breadcrumbs.call-bridge-flows', href: '/call-bridge' }
    ]
  },
  'create-call-bridge-flow': {
    crumbs: [
      { text: 'breadcrumbs.call-bridge', href: '/', disabled: true },
      { text: 'breadcrumbs.call-bridge-flows', href: '/call-bridge' },
      { text: 'breadcrumbs.create', href: '/call-bridge/create' }
    ]
  },
  'bridge-details': {
    crumbs: [
      { text: 'breadcrumbs.call-bridge', href: '/call-bridge', disabled: true },
      { text: 'breadcrumbs.call-bridge-calls', href: '/call-bridge' },
      {
        text: 'breadcrumbs.bridge-details',
        href: '/call-bridge/:id/bridge-details'
      }
    ]
  },
  'call-bridge-calls': {
    crumbs: [
      { text: 'breadcrumbs.call-bridge', href: '/call-bridge', disabled: true },
      { text: 'breadcrumbs.call-bridge-calls', href: '/call-bridge/calls' }
    ]
  },
  'create-call-bridge-call': {
    crumbs: [
      { text: 'breadcrumbs.call-bridge', href: '/call-bridge', disabled: true },
      { text: 'breadcrumbs.call-bridge-calls', href: '/call-bridge/calls' },
      { text: 'breadcrumbs.create', href: '/call-bridge/calls/create' }
    ]
  }
}
