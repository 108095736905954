<template>
  <aside class="vuestic-sidebar" :class="{ 'sidebar-hidden': hidden }">
    <vuestic-scrollbar>
      <ul class="sidebar-menu">
        <slot name="menu"></slot>
      </ul>
    </vuestic-scrollbar>
    <img
      :src="require('@/assets/images/inno-support.png')"
      alt="INNO SUPPORT"
      class="inno-support mx-auto"
    />

    <router-link
      v-if="
        currentUser &&
          currentUser.userType === 'user' &&
          currentUser.completeControlTicketing
      "
      :to="{ name: 'inno-support' }"
      class="inno-support-btn btn btn-sm btn-primary w-100"
      >{{ $t('sidebar.support') }}
    </router-link>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'vuestic-sidebar',
  props: {
    hidden: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>

<style lang="scss">
.vuestic-sidebar {
  @include media-breakpoint-down(md) {
    top: $sidebar-mobile-top;
    left: $sidebar-mobile-left;
    width: $sidebar-mobile-width;
    z-index: $sidebar-mobile-z-index;
  }

  height: $sidebar-viewport-height;
  position: absolute;
  width: $sidebar-width;
  top: $sidebar-top;
  left: $sidebar-left;
  transition: all 0.2s ease;
  opacity: 1;
  // z-index: 999;
  // float: left;
  // top: 30px;
  margin-top: 15px;
  @media (min-width: 992px) and (max-width: 1368px) {
    width: 191px;
  }
  .vuestic-scrollbar {
    @include media-breakpoint-down(md) {
      height: 100%;
    }

    .sidebar-menu {
      max-height: 100%;
      margin-bottom: 0;
      list-style: none;
      padding-left: 0;
      li {
        display: block;
        padding-left: 0;
        &:not(:last-child) {
          &:after {
            content: '';
            display: block;
            width: 80%;
            margin: 0 auto;
            border-bottom: 1px solid $light-gray;
          }
        }
      }
      ul.sidebar-submenu {
        li {
          &:after {
            all: unset;
          }
        }
      }
    }

    .scrollbar-wrapper {
      box-shadow: $sidebar-box-shadow;
      border-radius: $sidebar-border-radius;
    }

    .scrollbar-content {
      background: $sidebar-bg;
    }
  }
  .inno-support {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    max-width: 60%;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .inno-support-btn {
    font-size: 20px;
  }
  &.sidebar-hidden {
    @include media-breakpoint-down(md) {
      top: $sidebar-hidden-top-mobile;
      opacity: 0;
      z-index: $sidebar-mobile-z-index;
      height: $sidebar-hidden-height-mobile;
      display: none;
    }
    top: $sidebar-hidden-top;
    opacity: 0;
    z-index: $min-z-index;
    display: none;
  }
  &.sidebar-hidden + .content-wrap {
    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
    margin-left: $sidebar-left;
  }
}
</style>

/* RTL STYLES */
<style lang="scss">
.rtl {
  .vuestic-sidebar {
    top: $sidebar-top;
    left: unset;
    right: $sidebar-left;
    @include media-breakpoint-down(md) {
      right: $sidebar-mobile-left;
    }
    &.sidebar-hidden + .content-wrap {
      @include media-breakpoint-down(md) {
        margin-right: 0;
      }
      margin-right: $sidebar-left;
    }

    .vuestic-scrollbar {
      .sidebar-menu {
        padding-left: unset;
        padding-right: 0;
        li {
          padding-left: unset;
          padding-left: right;
        }
      }
      .scrollbar-content {
        .sidebar-menu-item-icon {
          margin-left: 15px;
        }
      }
    }
    .sidebar-link-group .sidebar-submenu {
      padding-right: 0;
    }
  }
}
</style>
