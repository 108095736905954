<template>
  <div class="px-4 py-3 navbar">
    <div class="align-items-center w-100 row">
      <div class="col-4">
        <span
          class="flex-center header-selector i-menu-expanded"
          @click="$emit('update:isOpen', !isOpen)"
        />
      </div>
      <div class="d-flex justify-content-center col-4">
        <img
          :src="require('@/assets/images/logo.svg')"
          class="navbar-logo"
          alt="Innocalls"
        />
      </div>
      <div class="d-flex justify-content-end align-items-center col-4">
        <p class="m-0 text-white">{{ timezone }}</p>
        <Dropdown align="right" disabled>
          <template #trigger>
            <span class="text-white" v-if="currentTenant">{{
              currentTenant.name
            }}</span>
            <span class="text-white" v-else>user</span>
          </template>
          <template #menu>
            <div v-if="currentTenant">
              <p class="m-0 px-3 py-1">{{ $t('navbar.organizations') }}</p>
              <DropdownItem
                v-for="organization in organizations"
                @click="setCurrentOrganization(organization)"
                :key="organization.id"
                :disabled="!!session"
              >
                {{ organization.name }}
              </DropdownItem>
            </div>
            <hr class="mx-3 my-2" />
            <DropdownItem
              @click="takeBreak()"
              v-if="currentUser && currentUser.userType === 'agent'"
            >
              <span class="d-flex align-items-center">
                <img src="../../../assets/icons/pause.svg" alt="" />
                <span class="mx-2">{{ $t('navbar.take-break') }}</span>
              </span>
            </DropdownItem>
            <DropdownItem to="/settings" v-if="currentUser.userType === 'user'">
              <span class="mx-2">{{ $t('navbar.settings') }}</span>
            </DropdownItem>
            <DropdownItem @click="userLogout()">
              <img src="../../../assets/icons/power.svg" alt="" />
              <span class="mx-2 text-danger">{{ $t('navbar.logout') }}</span>
            </DropdownItem>
          </template>
        </Dropdown>
        <language-toggle class="nav-item" />
      </div>
    </div>

    <b-modal
      id="breakModal"
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-header>
        <h5>{{ $t('navbar.break-modal.title') }}</h5>
      </template>
      <template #default>
        <div class="d-flex flex-column align-items-center">
          <img
            class="onBreak"
            src="../../../assets/images/onBreak.png"
            alt=""
          />
          <p class="text-center">{{ $t('navbar.break-modal.subtitle') }}</p>
          <button class="btn btn-primary btn-sm" @click="endBreak">
            {{ $t('navbar.break-modal.end-break') }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import LanguageToggle from './components/dropdowns/LanguageToggle'
import { mapGetters, mapActions } from 'vuex'
import Dropdown from '../../../elements/Dropdown.vue'
import DropdownItem from '../../../elements/DropdownItem.vue'
import agentService from '../../../services/agent.service'
import momentTimezone from 'moment-timezone'

export default {
  name: 'app-navbar',
  components: {
    LanguageToggle,
    Dropdown,
    DropdownItem
  },
  props: {
    isOpen: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      currentOrganization: null
    }
  },
  computed: {
    ...mapGetters(['organizations', 'currentTenant', 'currentUser', 'session']),
    timezone() {
      let timezone = momentTimezone.tz.guess()
      const regex = new RegExp('^[a-zA-Z_]+\\/[a-zA-Z_]+$')
      if (!regex.test(timezone)) {
        timezone = 'Asia/Riyadh'
      }
      return timezone
    }
  },
  watch: {
    currentUser(currentUser) {
      if (currentUser && currentUser.userType === 'agent') {
        this.checkHasUnfinishedBreak()
      }
    }
  },
  methods: {
    ...mapActions(['logout']),
    setCurrentOrganization(organization) {
      this.currentOrganization = organization
      localStorage.setItem('organizationId', organization.id)
      this.$store.commit('setCurrentTenant', organization)
      this.$store.commit('setReloadTabledata', true)
    },
    async takeBreak() {
      await agentService.toggleBreak()
      this.$store.dispatch('extensionLogout')
      this.$bvModal.show('breakModal')

      if (this.$socket)
        this.$socket.emit('agent-status', {
          data: {
            status: 'on_break'
          }
        })
    },
    async endBreak() {
      await agentService.toggleBreak()
      this.$bvModal.hide('breakModal')
      this.$store.dispatch('extensionLogin')

      if (this.$socket)
        this.$socket.emit('agent-status', {
          data: {
            status: 'idle'
          }
        })
    },
    userLogout() {
      // remove routes
      this.logout()
      this.$socket.emit('agent-status', {
        data: {
          status: 'disconnected'
        }
      })

      location.reload()
    },
    async checkHasUnfinishedBreak() {
      const hasUnfinishedBreak = await agentService.hasUnfinishedBreak()
      if (hasUnfinishedBreak) {
        this.$store.dispatch('extensionLogout')
        this.$bvModal.show('breakModal')
      }
    }
  }
}
</script>
<style lang="scss">
.navbar {
  background: $darkblue;
  position: sticky;
  top: 0px;
  z-index: 1;
  .nav-item {
    margin: 0 1rem;
  }
}
.navbar-logo {
  width: 240px;
}
.nav-username {
  font-weight: 500;
  color: $white;
  text-transform: uppercase;
}
.navbar-logout {
  display: flex;
  align-items: center;
  color: $white;
  margin: 0 15px;
  transition: color 0.1s ease-in;
  svg {
    fill: $white;
    transition: fill 0.1s ease-in;
  }
  &:hover {
    cursor: pointer;
    color: lighten($blue, 10%);
    svg {
      fill: lighten($blue, 10%);
    }
  }
  .navbar-logout-title {
    margin: 0 10px;
  }
}
@media (max-width: 575px) {
  .navbar-logo {
    width: 240px;
  }
}
img.onBreak {
  width: 450px;
  margin: auto;
}
</style>
