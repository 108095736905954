import UserLayout from '../../layouts/UserLayout.vue'
const EmptyParentComponent = {
  template: '<router-view></router-view>'
}
const userRoutes = [
  {
    path: '/',
    component: UserLayout,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import(`../../pages/user/Dashboard.vue`)
      },
      // extensions
      {
        path: 'extensions',
        name: 'all-extensions',
        component: () => import(`../../components/extensions/extensions.vue`)
      },
      {
        name: 'monitor-extensions',
        path: 'extensions/monitor',
        component: () => import(`../../pages/user/extensions/monitor.vue`)
      },
      {
        name: 'extension-create',
        path: 'extensions/create',
        component: () => import('../../pages/user/extensions/create.vue')
      },
      {
        name: 'extension-update',
        path: 'extensions/update/:id',
        component: () => import('../../pages/user/extensions/update.vue')
      },
      // call reporting
      {
        name: 'call-reporting',
        path: 'call-reporting',
        component: () => import('../../components/reporting/CallReporting.vue')
      },
      // billing
      {
        name: 'billing-rates',
        path: 'rates',
        component: () => import('../../components/billing/Rates.vue')
      },
      {
        name: 'billing-refill',
        path: 'refill',
        component: () => import('../../components/billing/Refill.vue')
      },
      {
        name: 'billing-payments',
        path: 'payments',
        component: () => import('../../components/billing/Payments.vue')
      },
      {
        name: 'billing-charges',
        path: 'charges',
        component: () => import('../../components/billing/Charges.vue')
      },
      {
        name: 'billing-invoices',
        path: 'invoices',
        component: () => import('../../components/billing/Invoices.vue')
      },

      {
        name: 'usage',
        path: 'usage',
        component: EmptyParentComponent,
        children: [
          {
            name: 'usage-summary',
            path: 'summary',
            component: () => import('../../pages/user/usage/UsageSummary.vue')
          },
          {
            name: 'usage-detailed',
            path: 'detailed',
            component: () => import('../../pages/user/usage/UsageDetailed.vue')
          }
        ]
      },
      {
        name: 'order-confirmation',
        path: 'order-confirmation',
        component: EmptyParentComponent,
        children: [
          // flows
          {
            name: 'order-confirmation-flows-list',
            path: 'flows',
            component: () =>
              import('../../components/order-confirmation/flows.vue')
          },
          {
            name: 'order-confirmation-create-flow',
            path: 'flows/create',
            component: () =>
              import('../../components/order-confirmation/create-flow.vue')
          },
          {
            name: 'order-confirmation-edit-flow',
            path: 'flows/edit/:flowId',
            component: () =>
              import('../../components/order-confirmation/edit-flow.vue')
          },
          // calls
          {
            name: 'order-confirmation-calls',
            path: 'calls',
            component: () =>
              import('../../components/order-confirmation/calls.vue')
          },
          {
            name: 'order-confirmation-excel-calls-list',
            path: 'calls/excel/list',
            component: () =>
              import('../../components/order-confirmation/excel-calls-list.vue')
          },
          {
            name: 'order-confirmation-excel-call-file',
            path: 'excel/:id',
            component: () =>
              import('../../components/order-confirmation/excel-call-file.vue')
          },
          {
            name: 'order-confirmation-calls-list',
            path: 'calls/:type/list',
            component: () =>
              import('../../components/order-confirmation/calls-list.vue')
          }
        ]
      },
      {
        name: 'call-campaign',
        path: 'call-campaign',
        component: EmptyParentComponent,
        children: [
          // flows
          {
            name: 'call-campaign-flows-list',
            path: 'flows',
            component: () => import('../../components/call-campaign/flows.vue')
          },
          {
            name: 'call-campaign-create-flow',
            path: 'flows/create',
            component: () =>
              import('../../components/call-campaign/create-flow.vue')
          },
          {
            name: 'call-campaign-edit-flow',
            path: 'flows/edit/:flowId',
            component: () =>
              import('../../components/call-campaign/edit-flow.vue')
          },
          // calls
          {
            name: 'call-campaign-calls',
            path: 'calls',
            component: () => import('../../components/call-campaign/calls.vue')
          },
          {
            name: 'call-campaign-excel-calls-list',
            path: 'calls/excel/list',
            component: () =>
              import('../../components/call-campaign/excel-calls-list.vue')
          },
          {
            name: 'call-campaign-excel-call-file',
            path: 'excel/:id',
            component: () =>
              import('../../components/call-campaign/excel-call-file.vue')
          },
          {
            name: 'call-campaign-calls-list',
            path: 'calls/:type/list',
            component: () =>
              import('../../components/call-campaign/calls-list.vue')
          }
        ]
      },
      // agents
      {
        name: 'all-agents',
        path: 'agents',
        component: () => import(`../../pages/user/agents/agents.vue`)
      },
      {
        name: 'agent-create',
        path: 'agents/create',
        component: () => import('../../pages/user/agents/create.vue')
      },
      {
        name: 'agent-update',
        path: 'agents/:id/update',
        component: () => import('../../pages/user/agents/update.vue')
      },
      {
        name: 'inno-support',
        path: 'inno-support',
        component: () => import('../../components/support/InnoSupport.vue')
      },
      {
        name: 'all-settings',
        path: 'settings',
        component: () => import('../../pages/user/settings/SettingsLayout.vue'),
        redirect: { name: 'account-settings' },
        children: [
          {
            name: 'account-settings',
            path: 'account-settings',
            component: () =>
              import(
                '../../pages/user/settings/account-settings/AccountSettingsLayout.vue'
              ),
            redirect: { name: 'change-password' },
            children: [
              {
                name: 'change-password',
                path: 'change-password',
                component: () =>
                  import(
                    '../../pages/user/settings/account-settings/ChangePassword.vue'
                  )
              },
              {
                name: 'update-organization-info',
                path: 'update-organization-info',
                component: () =>
                  import(
                    '../../pages/user/settings/account-settings/UpdateOrganizationInfo.vue'
                  )
              }
            ]
          },
          {
            name: 'call-settings',
            path: 'call-settings',
            component: () =>
              import(
                '../../pages/user/settings/call-settings/CallSettingsLayout.vue'
              ),
            redirect: { name: 'after-call' },
            children: [
              {
                name: 'after-call',
                path: 'after-call',
                component: () =>
                  import(
                    '../../pages/user/settings/call-settings/AfterCall.vue'
                  )
              },
              {
                name: 'default-survey',
                path: 'default-survey',
                component: () =>
                  import(
                    '../../pages/user/settings/call-settings/DefaultSurvey.vue'
                  )
              }
            ]
          }
        ]
      },
      {
        name: 'all-tags',
        path: 'tags',
        component: EmptyParentComponent,
        children: [
          // tags crud
          {
            name: 'tags',
            path: '',
            component: () => import('../../pages/user/tags/Tags.vue')
          }
        ]
      },
      {
        name: 'all-developers-tab',
        path: 'developers',
        component: EmptyParentComponent,
        children: [
          {
            name: 'all-webrtc-credentials',
            path: 'webrtc-credentials',
            component: EmptyParentComponent,
            children: [
              {
                name: 'webrtc-credentials',
                path: '',
                component: () =>
                  import(
                    '../../pages/user/developers/webrtc-credentials/List.vue'
                  )
              },
              {
                name: 'create-webrtc-credentials',
                path: 'create',
                component: () =>
                  import(
                    '../../pages/user/developers/webrtc-credentials/Create.vue'
                  )
              }
            ]
          },
          {
            name: 'all-zendesk-credentials',
            path: 'zendesk-credentials',
            component: EmptyParentComponent,
            children: [
              {
                name: 'zendesk-credentials',
                path: '',
                component: () =>
                  import(
                    '../../pages/user/developers/zendesk-credentials/List.vue'
                  )
              },
              {
                name: 'create-zendesk-credentials',
                path: 'create',
                component: () =>
                  import(
                    '../../pages/user/developers/zendesk-credentials/Create.vue'
                  )
              }
            ]
          },
          {
            name: 'all-api-credentials',
            path: 'api-credentials',
            component: EmptyParentComponent,
            children: [
              {
                name: 'api-credentials',
                path: '',
                component: () =>
                  import('../../pages/user/developers/api-credentials/List.vue')
              },
              {
                name: 'create-api-credentials',
                path: 'create',
                component: () =>
                  import(
                    '../../pages/user/developers/api-credentials/Create.vue'
                  )
              }
            ]
          },
          {
            name: 'all-web-call-credentials',
            path: 'web-call-credentials',
            component: EmptyParentComponent,
            children: [
              {
                name: 'web-call-credentials',
                path: '',
                component: () =>
                  import(
                    '../../pages/user/developers/web-call-credentials/List.vue'
                  )
              },
              {
                name: 'edit-web-call-credentials',
                path: ':id/edit',
                component: () =>
                  import(
                    '../../pages/user/developers/web-call-credentials/Edit.vue'
                  )
              }
            ]
          }
        ]
      },
      {
        name: 'all-dids',
        path: 'dids',
        component: EmptyParentComponent,
        children: [
          {
            name: 'dids',
            path: '',
            component: () => import('../../pages/user/dids/Dids.vue')
          }
        ]
      },
      {
        name: 'auto-dialer',
        path: 'auto-dialer',
        component: EmptyParentComponent,
        children: [
          {
            name: 'auto-dialer',
            path: '',
            component: () => import('../../pages/user/auto-dialer/list.vue')
          },
          {
            name: 'auto-dialer-create',
            path: 'create',
            component: () => import('../../pages/user/auto-dialer/create.vue')
          },
          {
            name: 'auto-dialer-attach-customers',
            path: ':id/attach-customers',
            component: () =>
              import('../../pages/user/auto-dialer/attach-customers.vue')
          },
          {
            name: 'auto-dialer-update',
            path: ':id/update',
            component: () => import('../../pages/user/auto-dialer/update.vue')
          },
          {
            name: 'auto-dialer-update-main-info',
            path: ':id/update-main-info',
            component: () =>
              import('../../pages/user/auto-dialer/update-main-info.vue')
          },
          {
            name: 'auto-dialer-corrupted-rows',
            path: ':id/corrupted-rows',
            component: () =>
              import('../../pages/user/auto-dialer/corrupted-rows.vue')
          },
          {
            name: 'auto-dialer-campaign-details',
            path: ':id/campaign-details',
            component: () =>
              import('../../pages/user/auto-dialer/campaign-details.vue')
          },
          {
            name: 'auto-dialer-campaign-cdrs',
            path: ':id/cdrs',
            component: () =>
              import('../../pages/user/auto-dialer/campaign-cdrs.vue')
          },
          {
            name: 'auto-dialer-campaign-uncompleted',
            path: ':id/uncompleted',
            component: () =>
              import('../../pages/user/auto-dialer/campaign-uncompleted.vue')
          }
        ]
      },
      {
        name: 'call-survey',
        path: 'call-survey',
        component: EmptyParentComponent,
        children: [
          {
            name: 'call-survey',
            path: '',
            component: () => import('../../pages/user/call-survey/list.vue')
          },
          {
            name: 'create-call-survey',
            path: 'create',
            component: () => import('../../pages/user/call-survey/create.vue')
          },
          {
            name: 'survey-details',
            path: ':id/survey-details',
            component: () =>
              import('../../pages/user/call-survey/survey-details.vue')
          },
          {
            name: 'attach-customers',
            path: ':id/attach-customers',
            component: () =>
              import('../../pages/user/call-survey/attach-customers.vue')
          },
          {
            name: 'corrupted-rows',
            path: ':id/corrupted-rows',
            component: () =>
              import('../../pages/user/call-survey/corrupted-rows.vue')
          },
          {
            name: 'survey-cdrs',
            path: ':id/cdrs',
            component: () =>
              import('../../pages/user/call-survey/survey-cdrs.vue')
          },
          {
            name: 'survey-uncompleted',
            path: ':id/uncompleted',
            component: () =>
              import('../../pages/user/call-survey/survey-uncompleted.vue')
          }
        ]
      },
      {
        name: 'post-call-surveys',
        path: 'post-call-surveys',
        component: EmptyParentComponent,
        children: [
          {
            name: 'post-call-surveys-list',
            path: '',
            component: () =>
              import('../../pages/user/post-call-surveys/list.vue')
          },
          {
            name: 'create-post-call-survey',
            path: 'create',
            component: () =>
              import('../../pages/user/post-call-surveys/create.vue')
          },
          {
            name: 'post-call-survey-details',
            path: ':id/survey-details',
            component: () =>
              import('../../pages/user/post-call-surveys/survey-details.vue')
          },
          {
            name: 'post-call-survey-cdrs',
            path: ':id/cdrs',
            component: () =>
              import('../../pages/user/post-call-surveys/survey-cdrs.vue')
          }
        ]
      },
      {
        name: 'survey-calls',
        path: 'survey-calls',
        component: EmptyParentComponent,
        children: [
          {
            name: 'survey-calls-list',
            path: '',
            component: () => import('../../pages/user/survey-calls/list.vue')
          }
        ]
      },
      {
        name: 'call-bridge',
        path: 'call-bridge',
        component: EmptyParentComponent,
        children: [
          {
            name: 'call-bridge-flows',
            path: '',
            component: () =>
              import('../../pages/user/call-bridge/flows-list.vue')
          },
          {
            name: 'call-bridge-calls',
            path: 'calls',
            component: () =>
              import('../../pages/user/call-bridge/calls-list.vue')
          },
          {
            name: 'create-call-bridge-flow',
            path: 'create',
            component: () =>
              import('../../pages/user/call-bridge/create-flow.vue')
          },
          {
            name: 'bridge-details',
            path: ':id/bridge-details',
            component: () =>
              import('../../pages/user/call-bridge/bridge-details.vue')
          },
          {
            name: 'create-call-bridge-call',
            path: 'calls/create',
            component: () =>
              import('../../pages/user/call-bridge/create-call.vue')
          }
        ]
      }
    ],
    meta: { requiresAuth: true, userType: 'user' }
  }
]

export default userRoutes
