<template>
  <div class="call-log">
    <div class="row no-gutters">
      <div class="d-flex align-items-center">
        <i class="fa fa-angle-left p-3" @click="$emit('showDialpad')"></i>
        <h4 class="m-0 py-2">{{ $t('webrtc.callLog') }}</h4>
      </div>
    </div>
    <div class="log">
      <div class="call p-2 my-2" v-for="call in callLog" :key="call.id">
        <div class="d-flex align-items-center w-100 text-left">
          <b-avatar
            variant="success"
            :text="callAvatarText(call.name)"
          ></b-avatar>
          <div
            class="flex-fill d-flex flex-column justify-content-between align-items-start"
          >
            <p class="name mx-3 mb-0" :title="call.name">
              <b>{{ call.name }}</b>
            </p>
            <p class="mx-3 mb-0 text-muted">
              <small>{{ call.time }}</small>
            </p>
          </div>
          <div class="actions d-flex align-items-center">
            <button
              class="btn btn-primary btn-sm shadow-none px-2 mx-1 py-2"
              @click="
                $emit('saveContact', { name: call.name, phone: call.number })
              "
            >
              <i class="fa fa-address-book" aria-hidden="true"></i>
            </button>
            <button
              class="btn btn-primary btn-sm shadow-none px-3 mx-1 py-2"
              @click="$emit('callContact', call.number)"
            >
              <i class="fa fa-phone" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="stats my-1">
          <div class="stat">
            <img
              class="icon"
              src="../../assets/icons/call-status/missed.svg"
              alt=""
            />
            <span>{{ call.stats.missed }}</span>
          </div>
          <div class="stat">
            <img
              class="icon"
              src="../../assets/icons/call-status/incoming.svg"
              alt=""
            />
            <span>{{ call.stats.incoming }}</span>
          </div>
          <div class="stat">
            <img
              class="icon"
              src="../../assets/icons/call-status/outgoing.svg"
              alt=""
            />
            <span>{{ call.stats.outgoing }}</span>
          </div>
          <div class="stat">
            <img
              class="icon"
              src="../../assets/icons/call-status/rejected.png"
              alt=""
            />
            <span>{{ call.stats.rejected }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLatestCallLog } from '../../utils/callLog'

export default {
  data() {
    return {}
  },
  props: {
    extension: {
      type: String
    }
  },
  computed: {
    callLog() {
      return getLatestCallLog(this.extension)
    }
  },
  methods: {
    callAvatarText(name) {
      if (!!name) {
        const [firstPart, secondPart] = name.split(' ')

        const firstLetter = firstPart ? firstPart.slice(0, 1) : ''
        const secondLetter = secondPart ? secondPart.slice(0, 1) : ''
        return firstLetter + secondLetter
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.call {
  cursor: pointer;
  border-radius: 12px;
  &:hover {
    background: #ededed;
  }

  .name {
  }
}
.call-log .log {
  max-height: 400px;
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #b8b8b8;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #777777;
  }
}

.stats {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  .stat {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .icon {
      width: 24px;
    }
  }
}
</style>
