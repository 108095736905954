import { api } from '../axios'
import { toQueryString } from '../utils/utils'

const objToQueryParams = obj => {
  return Object.keys(obj)
    .map(key => `${key}=${encodeURIComponent(obj[key])}`)
    .filter(Boolean)
    .join('&')
}

const fetchAgentPostCallSurveys = async filters => {
  const filtersString = objToQueryParams(filters)
  const res = await api.get(`agent-surveys?${filtersString}`)
  return res.data
}
const fetchPostCallSurveys = async filters => {
  const filtersString = objToQueryParams(filters)
  const res = await api.get(`survey-post-call-templates?${filtersString}`)
  return res.data
}

const submitSurvey = async data => {
  const res = await api.post(`post-call-surveys`, data)
  return res.data
}

const getCDRs = async (page = 1, limit = 10) => {
  const res = await api.get(`post-call-surveys?page=${page}&limit=${limit}`)
  return res.data
}

const getOneSurvey = async id => {
  const res = await api.get(`survey-post-call-templates/${id}`)
  return res.data
}

const fetchSurveyAnalytics = async surveyId => {
  const res = await api.get(`survey-post-call-templates/${surveyId}/analytics`)
  return res.data
}

const fetchSurveyCDRs = async (id, filters, page = 1, limit = 10) => {
  const filtersString = toQueryString(filters)
  const res = await api.get(
    `survey-post-call-templates/${id}/calls?page=${page}&limit=${limit}&${filtersString}`
  )
  return res.data
}

const downloadReport = async surveyId => {
  const res = await api.get(
    `survey-post-call-templates/${surveyId}/calls/export`
  )
  return res.data
}

const createSurvey = async data => {
  const res = await api.post('survey-post-call-templates', data)
  return res.data
}

const PostCallSurveyService = {
  fetchAgentPostCallSurveys,
  fetchPostCallSurveys,
  submitSurvey,
  getOneSurvey,
  getCDRs,
  fetchSurveyCDRs,
  fetchSurveyAnalytics,
  downloadReport,
  createSurvey
}

export default PostCallSurveyService
