<template>
  <vuestic-layout :class="{ rtl }" :key="currentTenant.id">
    <app-navbar :isOpen="isSidebarOpen" @update:isOpen="toggleSidebar" />
    <sidebar :isOpen="isSidebarOpen" />
    <main
      slot="content"
      id="content"
      class="content fluid gutter--lg va-layout"
      role="main"
    >
      <app-breadcrumbs />
      <div class="pre-loader--container" v-show="isLoading">
        <semipolar-spinner
          slot="loading"
          :animation-duration="1500"
          :size="150"
          color="#008ecf"
          class="pre-loader"
        />
      </div>
      <router-view></router-view>
    </main>
    <div slot="footer" class="text-center footer">
      <!-- <router-link
        :to="{ name: 'inno-support' }"
        class="inno-support"
        v-if="!isSidebarOpen"
      >
        <img
          :src="require('@/assets/images/inno-support.png')"
          alt="INNO SUPPORT"
        />
        <span>
          {{ $t('inno-support.title') }}
        </span>
      </router-link> -->
      <p class="copyright-text">
        Copyright {{ new Date().getFullYear() }} &copy;
        <a href="https://innocalls.com/" target="_blank">Innocalls</a>
      </p>
      <WebRTC v-if="currentTenant && currentTenant.hasTenant"></WebRTC>
    </div>
  </vuestic-layout>
</template>

<script>
import Vue from 'vue'
import AppNavbar from '../components/admin/app-navbar/AppNavbar'
import Sidebar from '../components/agent/Sidebar'
import VuesticLayout from '../vuestic-theme/vuestic-components/vuestic-layout/VuesticLayout'
import AppBreadcrumbs from '../components/admin/app-breadcrumbs/AppBreadcrumbs'
import SemipolarSpinner from 'epic-spinners/src/components/lib/SemipolarSpinner'
import WebRTC from '../containers/WebRTC'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'agent-layout',
  components: {
    AppNavbar,
    Sidebar,
    VuesticLayout,
    AppBreadcrumbs,
    SemipolarSpinner,
    WebRTC
  },
  mounted() {
    const locale = localStorage.getItem('locale') || 'en'
    Vue.i18n.set(locale)

    const token = localStorage.getItem('token')

    if (!!token) {
      const user = this.fetchCurrentAgent()
    }
  },
  data() {
    return {
      isSidebarOpen: true,
      rtl: false,
      isVirtualPhoneOpen: false
    }
  },
  methods: {
    ...mapActions(['fetchCurrentAgent']),
    toggleSidebar(opened) {
      this.isSidebarOpen = opened
      this.$store.commit('setSidebarOpen', opened)
    }
  },
  computed: {
    ...mapGetters(['isLoading', 'currentTenant']),
    locale() {
      return Vue.i18n.locale()
    }
  },
  watch: {
    locale: function(val) {
      if (val === 'ar') {
        this.rtl = true
      } else {
        this.rtl = false
      }
    }
  }
}
</script>
