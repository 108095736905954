<template>
  <b-dropdown-item :to="to" @click="$emit('click')" :disabled="disabled">
    <slot>dropdown item</slot>
  </b-dropdown-item>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped></style>
