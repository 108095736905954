<template>
  <div class="va-row mb-2">
    <div class="flex md12 xs12">
      <div class="p-3 card">
        <div class="d-flex align-items-center crumbs">
          <div class="d-flex align-items-center crumb">
            <router-link to="/">
              <i class="fa fa-home"></i>
            </router-link>
            <div class="separator">
              <i class="fa-angle-right fa"></i>
            </div>
          </div>
          <div
            class="d-flex align-items-center crumb"
            v-for="(crumb, index) in currentBreadcrumbs"
            :key="crumb"
          >
            <router-link
              :to="crumb.href"
              :is="
                crumb.disabled || index === currentBreadcrumbs.length - 1
                  ? 'span'
                  : 'router-link'
              "
            >
              <p class="m-0">{{ $t(crumb.text) }}</p>
            </router-link>
            <div class="separator" v-if="index < currentBreadcrumbs.length - 1">
              <i class="fa-angle-right fa"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { breadcrumbs } from './Breadcrumbs'

export default {
  name: 'app-breadcrumbs',
  data() {
    return {
      breadcrumbs: breadcrumbs
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
    currentBreadcrumbs() {
      return this.breadcrumbs[this.currentRouteName]
        ? this.breadcrumbs[this.currentRouteName].crumbs
        : []
    }
  }
}
</script>
<style lang="scss" scoped>
.crumbs {
  .crumb {
    .separator {
      margin: 0 10px;
    }
  }
}
</style>
